<template>
  <body class="control-sidebar-slide-open sidebar-mini">
    <!-- <div class="wrapper"> -->
    <NavBar v-if="isUserLoggedIn"></NavBar>
    <GuestNavbar v-else></GuestNavbar>
    <!-- <div class="wrapper"> -->
      <SideBar v-if="isUserLoggedIn"></SideBar>
    <!-- </div> -->
    <div class="content-wrapper" v-if="isUserLoggedIn">
      <section class="content">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </section>
    </div>
    <div v-else>
      <section class="content">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </section>
    </div>
  <!-- </div> -->
  </body>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import { computed } from "vue";
import "../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css";
import "../node_modules/admin-lte/plugins/select2/css/select2.min.css";
import GuestNavbar from "./components/dashboard/GuestNavbar.vue";
import NavBar from "./components/dashboard/NavBar.vue";
import SideBar from "./components/dashboard/SideBar.vue";
export default {
  name: "App",
  components: {
    GuestNavbar,
    NavBar,
    SideBar,
  },
  data() {
    return {
      isUserLoggedIn: this.$userLoggedIn,
    };
  },
  provide() {
    return {
      userLoggedInStatus: computed({
        get: () => this.isUserLoggedIn,
        set: (value) => {
          this.isUserLoggedIn = value;
        },
      }),
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.show-pointer:hover{
  cursor: pointer;
}


/* a.router-link-active {
  background-color: rgba(189, 189, 189, 0.252);
  color: white;
} */
</style>
