<template>
  <div class="col-lg-3 col-6">
    <!-- small box -->
    <div :class="cardClass">
      <div class="inner">
        <h3>{{ mainData }}</h3>
        <p>{{ message }}</p>
      </div>
      <div class="icon">
        <i :class="iconClass"></i>
      </div>
      <router-link v-if="link" :to="link" class="small-box-footer"
        >More info <i class="fas fa-arrow-circle-right"></i
      ></router-link>
      <span class="small-box-footer" v-else>No More Data</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["cardClassType", "message", "mainData", "link", "icon"],
  computed: {
    cardClass() {
      if (this.cardClassType === "success") {
        return "small-box bg-success";
      } else if (this.cardClassType === "warning") {
        return "small-box bg-warning";
      } else if (this.cardClassType === "danger") {
        return "small-box bg-danger";
      } else if (this.cardClassType === "info") {
        return "small-box bg-info";
      } else {
        return "card";
      }
    },
    iconClass() {
      if (this.icon === "user") {
        return "fas fa-user";
      }
      else if(this.icon === "time-end"){
        return "fas fa-hourglass-end"
      }
      else if(this.icon === "cross"){
        return "fas fa-times"
      }
      else if(this.icon === "wind"){
        return "fa-solid fa-wind";
      }
      else if(this.icon === "clean"){
        return "fa-solid fa-spray-can-sparkles"
      }
      else {
        return "";
      }
    },
  },
};
</script>
