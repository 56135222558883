<template>
  <button
    type="button"
    :class="btnClass"
    data-bs-toggle="modal"
    :data-bs-target="getTarget()"
  >
    <slot name="btn-title"></slot>
  </button>

  <div
    class="modal fade"
    :id="uniqueKey"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="theModal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            <slot name="dialog-header"></slot>
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" @click="success()">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
export default {
  props: ["btnClass","uniqueKey"],
  emits: ["on-success"],
  data() {
    return {
      theModal: null,
      isVisible :false,
    };
  },
  methods: {
    success() {
      this.$emit("on-success", this.theModal);
    },
    getTarget(){
      return `#${this.uniqueKey}`
    }
  },
  mounted() {
    this.theModal = new Modal(this.$refs.theModal);
  },
};
</script>
