<template>
  <TableView>
    <template #card-header>
      <h3 class="card-title">All Air-Conditioners</h3>
      <div class="row">
        <div class="ml-auto col-2">
          <add-new-company
            @on-success="fetchCompanyData"
            :uniqueKey="'addData'"
          />
        </div>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Compnay Name</th>
      <th>AC Count</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr v-for="(company, index) in companyDetails" :key="company.company_id">
        <td>{{ index + 1 }}</td>
        <td>{{ company.company_name }}</td>
        <td>{{ company.ac_count }}</td>
        <td>
          <span
            @click="editHandler(company.company_id, company.company_name)"
            class="show-pointer"
          >
            <i
              class="fas fa-edit p-2"
              style="color: rgb(255, 153, 0); font-size: 22px"
            ></i>
          </span>
          <span @click="deleteHandler(company.company_id)" class="show-pointer">
            <i class="fas fa-trash p-2" style="color: red; font-size: 22px"></i>
          </span>
        </td>
      </tr>
    </template>
  </TableView>
</template>
<script>
import TableView from "../UI/TableView.vue";
import AddNewCompany from "./AddNewCompany.vue";
import Swal from "sweetalert2";
export default {
  components: { TableView, AddNewCompany },
  data() {
    return {
      companyDetails: [],
    };
  },
  methods: {
    editHandler(id, companyName) {
      this.$router.push(`/editAcCompany/${id}/${companyName}`);
    },
    fetchCompanyData() {
      this.axios
        .get(this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.AC_COMPANY)
        .then((res) => {
          if (res.status === 200) {
            this.companyDetails = res.data.data;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    deleteHandler(id) {
      Swal.fire({
        title: "Are you sure?",
        html:
          "Are you sure you want to delete the selected company",
        icon: "warning",
        allowEnterKey: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(
              this.$Constants.BaseUrl +
                this.$Constants.ApiEndpoints.AC_COMPANY +
                `/${id}`
            )
            .then((res) => {
              if (res.status === 200) {
                Swal.fire(
                  "Deleted Successfully!",
                  "The Company has been deleted successfully.",
                  "success"
                );
              }
            })
            .catch((err) => {
              this.$Methods.toastError(err.response.data.message);
            });
        }
      });
    },
  },
  mounted() {
    this.fetchCompanyData();
  },
};
</script>
