<template>
  <table-view>
    <template #card-header> Cleaning History</template>
    <template #table-header>
      <th>Sr No.</th>
      <th>AC Unique Name</th>
      <th>Date</th>
      <th>Images</th>
    </template>
    <template #default>
      <tr v-for="(clean,index) in historyData" :key="clean.ac_clean_id" :class="tableRowClass(clean.status)">
        <td>{{ index+1 }}</td>
        <td>{{ clean.unique_alias }}</td>
        <td>{{ clean.created_at}}</td>
        <td><router-link :to="'/showImages/'+clean.ac_clean_id">Images</router-link></td>
      </tr>
    </template>
  </table-view>
</template>
<script>
import TableView from '../UI/TableView.vue';
export default {
  data() {
    return {
      historyData: {},
    };
  },
  props: ["caretakerData"],
  components:{TableView},
  methods:{
    fetchCaretakerCleanHistory(){
      this.axios
      .get(
        this.$Constants.BaseUrl +
          this.$Constants.ApiEndpoints.FETCH_STAFF_CLEAN_HISTORY +
          "/" +
          this.caretakerData.staff_id,
        {
          headers: {
            Authorization: "Bearer " + this.$token,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          this.historyData = res.data.data;
        } else if (res.status === 204) {
          this.$Methods.toastError("No cleaning Data found");
          this.historyData = {};
        }
      })
      .catch((err) => {
        this.$Methods.toastError(err.response.data.message);
      });
    },
    tableRowClass(status){
      if(status==="APPROVED"){
        return 'table-success'
      }
      else if(status==="REJECTED"){
        return 'table-danger'
      }
    }
    
  },
  mounted() {
    this.fetchCaretakerCleanHistory();
  },
};
</script>
