<template>
  <backdrop-dialog-close-success
    btnClass="btn btn-success"
    @onSuccess="onSuccess"
    :key="uniqueKey"
    :uniqueKey="uniqueKey"
  >
    <template #btn-title>Add New</template>
    <template #dialog-header>Add New Company</template>
    <template #default>
      <form>
        <div class="mb-3">
          <label for="companyName" class="form-label">Company Name</label
          ><asterisk-symbol />
          <input
            type="text"
            class="form-control"
            id="companyName"
            aria-describedby="emailHelp"
            v-model="companyName"
          />
        </div>
      </form>
    </template>
  </backdrop-dialog-close-success>
</template>
<script>
import BackdropDialogCloseSuccess from "../UI/BackdropDialogCloseSuccess.vue";
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
export default {
  components: {
    BackdropDialogCloseSuccess,
    AsteriskSymbol,
  },
  props:['uniqueKey'],
  emits: ["on-success"],
  data() {
    return {
      companyName: "",
    };
  },
  methods: {
    onSuccess(modalInstance) {
      const formBody = { company_name: this.companyName };
      this.axios
        .post(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.AC_COMPANY,
          formBody
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            this.$Methods.toastSuccess(res.data.message);
            modalInstance.hide();
            this.$emit("on-success");
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
      modalInstance.hide();
    },
  },
};
</script>
