<template>
  <select-supervisor @show-box="showAssignBoxHandler"></select-supervisor>
  <div class="container">
    <card-layout>
      <div class="row">
        <div class="text-center">
          <base-button
            class="col-2 m-3"
            :classType="
              selectedCmp === 'AssignAcSupervisor' ? 'info' : 'outline-info'
            "
            @click="selectedComponent('AssignAcSupervisor')"
            >Assign</base-button
          >
          <base-button
            class="col-2 m-3"
            :classType="
              selectedCmp === 'UnAssignAcSupervisor' ? 'info' : 'outline-info'
            "
            @click="selectedComponent('UnAssignAcSupervisor')"
            >Un-Assign</base-button
          >
        </div>
      </div>
    </card-layout>
    <keep-alive>
      <div v-if="showAssignBox">
        <component :is="selectedCmp" :staffData="staffData"></component>
      </div>
    </keep-alive>
  </div>
</template>

<script>
import SelectSupervisor from "./SelectSupervisor.vue";
import AssignAcSupervisor from "./AssignAcSupervisor.vue";
import UnAssignAcSupervisor from "./UnAssignAcSupervisor.vue";
export default {
  components: { SelectSupervisor, AssignAcSupervisor, UnAssignAcSupervisor },
  data() {
    return {
      selectedCmp: "AssignAcSupervisor",
      showAssignBox: false,
      staffData: null,
    };
  },
  methods: {
    selectedComponent(cmp) {
      this.selectedCmp = cmp;
    },
    showAssignBoxHandler(status, staff) {
      this.showAssignBox = status;
      this.staffData = staff;
    },
  },
};
</script>
