<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <InfoCard
          cardClassType="success"
          :mainData="dashboardData.cleanedOutOfTotal"
          message="AC cleaned in past 7 Days"
          link="/dateWiseReport"
          icon="clean"
        ></InfoCard>
        <InfoCard
          cardClassType="info"
          :mainData="dashboardData.totalAcCount"
          message="Total AC"
          link="/viewAllAirConditioner"
          icon="wind"
        ></InfoCard>
        <InfoCard
          cardClassType="info"
          :mainData="dashboardData.activeStaffCount"
          message="Active Caretakers"
          link="/viewAllStaff"
          icon="user"
        ></InfoCard>
      </div>
    </div>
  </section>
</template>
<script>
import InfoCard from "../dashboard/InfoCard.vue";
export default {
  components: {
    InfoCard,
  },
  data() {
    return {
      dashboardData: {},
      startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 7)
        .toISOString()
        .split("T")[0],
      endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0],
    };
  },
  methods: {
    fetchDashboardData() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_DASHBOARD_DATA +
            "/" +
            this.startDate +
            "/" +
            this.endDate,
          {
            headers: {
              Authorization: "Bearer " + this.$token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.dashboardData = res.data.data;
          }
        })
        .catch(() => {
          this.$Methods.toastError("Error in fetching Data");
        });
    },
  },
  mounted() {
    this.fetchDashboardData();
  },
};
</script>
