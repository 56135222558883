<template>
  <div class="col-md-9 mx-auto mt-2">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Add New Air Conditioner</h3>
      </template>

      <form class="form-horizontal" @submit.prevent="submitHandler()">
        <div class="card-body">
          <div class="form-group row">
            <label for="ac_company" class="col-sm-3 col-form-label"
              >AC Company<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <multi-select
                v-model="selectedCompany"
                :options="companyName"
                :searchable="true"
                placeholder="Select a Company"
              ></multi-select>

              <ErrorMessage v-if="companyNameError">{{
                companyNameError
              }}</ErrorMessage>
            </div>
          </div>
          <div class="form-group row">
            <label for="ac_model" class="col-sm-3 col-form-label"
              >Enter AC Model</label
            >
            <div class="col-sm-9">
              <input type="text" class="form-control" v-model="acModel" />
              <ErrorMessage v-if="acModelError">{{
                acModelError
              }}</ErrorMessage>
            </div>
          </div>

          <div class="form-group row">
            <label for="ac_capacity" class="col-sm-3 col-form-label"
              >AC Capacity<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <input
                type="number"
                min="0.5"
                step="0.1"
                style="width: 100%"
                class="form-control"
                v-model="acCapacity"
              />

              <ErrorMessage v-if="acCapacityError">{{
                acCapacityError
              }}</ErrorMessage>
            </div>
          </div>

          <div class="form-group row">
            <label for="ac_type" class="col-sm-3 col-form-label"
              >AC Type<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <multi-select
                v-model="acType"
                :options="acTypes"
                :searchable="true"
                placeholder="Select AC Type"
              ></multi-select>
              <ErrorMessage v-if="acTypeError">{{ acTypeError }}</ErrorMessage>
            </div>
          </div>

          <div class="form-group row" v-if="acType === 'split'">
            <label for="ac_model" class="col-sm-3 col-form-label"
              >Enter Outdoor unit Model</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="outdoorUnitModel"
              />
              <ErrorMessage v-if="outdoorUnitModelError">{{
                outdoorUnitModelError
              }}</ErrorMessage>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="building" class="col-sm-3 col-form-label"
              >Select Building<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <multi-select
                v-model="buildingName"
                :options="allBuildings"
                :searchable="true"
                placeholder="Select Building"
              ></multi-select>
              <!-- <ErrorMessage v-if="resourceNameError"
                >Please select a valid resource name</ErrorMessage
              > -->
            </div>
          </div>

          <div class="form-group row">
            <label for="installation_Date" class="col-sm-3 col-form-label"
              >Installation Date:-<AsteriskSymbol
            /></label>
            <div class="col-sm-3">
              <vue-date-picker
                :max-date="new Date()"
                :time="false"
                format="dd-MM-yyyy"
                type="datetime"
                v-model="installationDate"
              ></vue-date-picker>
              <ErrorMessage v-if="installationDateError">{{
                installationDateError
              }}</ErrorMessage>
            </div>
            <label for="warranty_end_Date" class="col-sm-3 col-form-label"
              >Warranty End Date:-<AsteriskSymbol
            /></label>
            <div class="col-sm-3">
              <vue-date-picker
                :min-date="new Date()"
                format="dd-MM-yyyy"
                type="datetime"
                :time="false"
                v-model="warrantyEndDate"
              ></vue-date-picker>
              <ErrorMessage v-if="warrantyEndDateError">{{
                warrantyEndDateError
              }}</ErrorMessage>
            </div>
          </div>

          <div class="form-group row">
            <label for="ac_model" class="col-sm-3 col-form-label"
              >Upload Bill<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <input
                type="file"
                class="form-control"
                ref="billImage"
                @change="uploadFile"
              />
              <ErrorMessage v-if="bill_img_error">
                <p v-for="err in bill_img_error" :key="err">
                  {{ err }}
                </p>
              </ErrorMessage>
            </div>
          </div>
          <div class="form-group row">
            <label for="ac_capacity" class="col-sm-3 col-form-label"
              >Select Resource<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <multi-select
                v-model="resourceName"
                :options="allResources"
                :searchable="true"
                placeholder="Select Resource"
              ></multi-select>
              <ErrorMessage v-if="resourceNameError"
                >Please select a valid resource name</ErrorMessage
              >
            </div>
          </div>
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-primary custom-control-input-outline"
              type="checkbox"
              v-model="subResourceEntry"
              id="customCheckbox5"
              disabled
            />
            <label for="customCheckbox5" class="custom-control-label"
              >Do the resource have sub resource</label
            >
          </div>

          <div class="form-group row mt-4" v-if="subResourceEntry">
            <label for="ac_capacity" class="col-sm-3 col-form-label"
              >Select Sub Resource<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <!-- <select
                style="width: 100%"
                class="form-control"
                v-model="subResourceName"
              >
                <option value="">Select</option>
                <option
                  v-for="subresource in allSubResources"
                  :key="subresource.sub_resource_id"
                  :value="subresource.sub_resource_id"
                >
                  {{ subresource.sub_resource_name }}
                </option>
              </select> -->
              <multi-select
                v-model="subResourceName"
                :options="allSubResources"
                :searchable="true"
                placeholder="Select Resource"
              ></multi-select>
              <ErrorMessage v-if="subResourceNameError"
                >Please select a valid sub resource name</ErrorMessage
              >
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-info">Add New AC</button>
        </div>
      </form>
    </base-card>
  </div>
</template>
<script>
import ErrorMessage from "../UI/ErrorMessage.vue";
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import axios from "axios";

export default {
  data() {
    return {
      subResourceEntry: false,
      allResources: {},
      acModel: "",
      acCapacity: "1",
      billImage: null,
      resourceName: "",
      allSubResources: {},
      subResourceName: "",
      allBuildings: {},
      buildingName: "",
      acType: "window",
      outdoorUnitModel: null,
      installationDate: null,
      warrantyEndDate: null,

      companyName: {},
      companyNameError: false,
      selectedCompany: "",

      bill_img_error: false,
      acModelError: false,
      acCapacityError: false,
      resourceNameError: false,
      subResourceNameError: false,
      buildingNameError: false,
      acTypeError: false,
      outdoorUnitModelError: false,
      installationDateError: false,
      warrantyEndDateError: false,

      acTypes: [
        {
          label: "Window",
          value: "window",
        },
        {
          label: "Split",
          value: "split",
        },
        {
          label: "Casset",
          value: "casset",
        },
        {
          label: "Duct",
          value: "duct",
        },
      ],
    };
  },
  watch: {
    buildingName() {
      this.fetchResourcesBuildingWise();
    },
    resourceName() {
      this.fetchSubResource();
    },
  },
  methods: {
    submitHandler() {
      this.outdoorUnitModelError = false;
      let data;
      if (this.subResourceEntry) {
        data = {
          model_no: this.acModel,
          ac_capacity: this.acCapacity + "Ton",
          subresource_ref_id: this.subResourceName,
          outdoor_unit_model: this.outdoorUnitModel,
          ac_type: this.acType,
          ac_company_name: this.selectedCompany,
          bill_image: this.billImage,
          installation_date: new Date(this.installationDate)
            .toISOString()
            .split("T")[0],
          warranty_end_date: new Date(this.warrantyEndDate)
            .toISOString()
            .split("T")[0],
        };
      } else {
        data = {
          model_no: this.acModel,
          ac_capacity: this.acCapacity + "Ton",
          resource_ref_id: this.resourceName,
          bill_image: this.billImage,
          outdoor_unit_model: this.outdoorUnitModel,
          ac_type: this.acType,
          ac_company_name: this.selectedCompany,
          installation_date: new Date(this.installationDate)
            .toISOString()
            .split("T")[0],
          warranty_end_date: new Date(this.warrantyEndDate)
            .toISOString()
            .split("T")[0],
        };
      }

      axios
        .post(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.AIR_CONDITIONER_API,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.$router.push("/viewAllAirConditioner");
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
          if (err.response.status === 422) {
            if (err.response.data.errors.model_no) {
              this.acModelError = err.response.data.errors.model_no[0];
            } else {
              this.acModelError = false;
            }
            if (err.response.data.errors.ac_capacity) {
              this.acCapacityError = err.response.data.errors.ac_capacity;
            } else {
              this.acCapacityError = false;
            }
            if (err.response.data.errors.resource_ref_id) {
              this.resourceNameError = "Select a valid resource";
            } else {
              this.resourceNameError = false;
            }
            if (err.response.data.errors.subresource_ref_id) {
              this.subResourceNameError = "Select a valid Sub-resource";
            } else {
              this.subResourceNameError = false;
            }
            if (err.response.data.errors.ac_type) {
              this.acTypeError = "Select a valid AC type";
            } else {
              this.acTypeError = false;
            }
            if (err.response.data.errors.ac_company_name) {
              this.companyNameError = "Please select a valid company name";
            } else {
              this.companyNameError = false;
            }
            if (err.response.data.errors.installation_date) {
              this.installationDateError =
                "Please select a valid installation date";
            } else {
              this.installationDateError = false;
            }
            if (err.response.data.errors.bill_image) {
              this.bill_img_error = err.response.data.errors.bill_image;
            } else {
              this.bill_img_error = false;
            }
            if (err.response.data.errors.warranty_end_date) {
              this.warrantyEndDateError =
                "Please select a valid warranty end date";
            } else {
              this.warrantyEndDateError = false;
            }
          }
        });
      // }
    },
    uploadFile() {
      this.billImage = this.$refs.billImage.files[0];
    },
    fetchAllCompanyName() {
      axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ALL_COMPANY_NAME
        )
        .then((res) => {
          if (res.status === 200) {
            this.companyName = res.data.data;
          }
        });
    },
    fetchAllBuildings() {
      axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ALL_BUILDINGS
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            arr.forEach((obj) => {
              this.$Methods.renameKey(
                obj,
                "building_name",
                "label",
                "building_id",
                "value"
              );
            });
            this.allBuildings = arr;
          }
        });
    },
    fetchResourcesBuildingWise() {
      axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_RESOURCE_BUILDING_WISE +
            "/" +
            this.buildingName,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            arr.forEach((obj) => {
              this.$Methods.renameKey(
                obj,
                "resource_name",
                "label",
                "resource_id",
                "value"
              );
            });
            this.allResources = arr;
          } else if (res.status === 204) {
            this.allResources = {};
            this.resourceName = "";
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    fetchSubResource() {
      axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_SUB_RESOURCE_OF_RESOURCE +
            "/" +
            this.resourceName,
        )
        .then((res) => {
          if (res.status === 200) {
            this.subResourceEntry = true;
            const arr = res.data.data;
            arr.forEach((obj) => {
              this.$Methods.renameKey(
                obj,
                "sub_resource_name",
                "label",
                "sub_resource_id",
                "value"
              );
            });
            this.allSubResources = arr;
          } else if (res.status === 204) {
            this.subResourceEntry = false;
            this.allSubResources = {};
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Methods.toastError("Something went wrong in Sub Resource");
        });
    },
  },
  components: {
    ErrorMessage,
    AsteriskSymbol,
  },
  mounted() {
    this.fetchAllBuildings();
    this.fetchAllCompanyName();
  },
};
</script>
<style></style>
