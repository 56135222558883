<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Select Supervisor</h3>

        <div class="card-tools">
          <button
            type="button"
            class="btn btn-tool"
            data-card-widget="collapse"
          >
            <i class="fas fa-minus"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-5">
            <div class="my-3">
              <label>Select Building</label>
              <multi-select
                :options="allBuildings"
                :searchable="true"
                placeholder="Select Building"
                v-model="selectedBuilding"
              ></multi-select>
            </div>
            <div class="my-3" v-if="showDepartmentField">
              <label>Select Department</label>
              <multi-select
                :options="allDepartments"
                :searchable="true"
                placeholder="Select Department"
                v-model="selectedDepartment"
              ></multi-select>
            </div>
            <div class="my-3" v-if="showSelectStaffField">
              <label>Select Supervisor</label>
              <multi-select
                :options="allStaff"
                :searchable="true"
                placeholder="Select Supervisor"
                v-model="selectedStaff"
              >
                <template v-slot:singlelabel="{ value }">
                  <div class="multiselect-single-label">
                    {{ value.staff_unique_id }} | {{ value.label }}
                    {{ value.staff_lastname }}
                  </div>
                </template>
              </multi-select>
            </div>
          </div>

          <div class="col-sm-5 ml-5">
            <p class="text-decoration-underline" style="font-weight: 800">
              Supervisor Details
            </p>
            <table class="table table-bordered" v-if="showStaffData">
              <tr>
                <td>Name :</td>
                <td :class="staffDataClass">
                  {{ staffData.staff_firstname }}
                  {{ staffData.staff_lastname }}
                </td>
              </tr>
              <tr>
                <td>Email :</td>
                <td :class="staffDataClass">{{ staffData.staff_email }}</td>
              </tr>
              <tr>
                <td>Phone :</td>
                <td :class="staffDataClass">
                  {{ staffData.staff_mobile }}
                </td>
              </tr>
              <tr>
                <td>Unique No :</td>
                <td :class="staffDataClass">
                  {{ staffData.staff_unique_id }}
                </td>
              </tr>
              <tr>
                <td>Active Status :</td>
                <td :class="staffDataClass">
                  {{ staffData.is_active ? "Active" : "InActive" }}
                </td>
              </tr>
              <tr>
                <td>AC Assign Count :</td>
                <td
                  class="show-pointer fake-link"
                  :class="staffDataClass"
                  title="Click to See the details of AC"
                  @click="showAssignedACData"
                >
                  {{ acCount }}
                </td>
              </tr>
            </table>
            <loading-screen v-else></loading-screen>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingScreen from "../shared/LoadingScreen.vue";
export default {
  components: { LoadingScreen },
  data() {
    return {
      showSearchstaff: true,
      showDepartmentField: false,
      showSelectStaffField: false,
      showStaffData: false,
      acCount: 0,
      assignedACData: null,

      allBuildings: {},
      allDepartments: {},
      allStaff: {},

      selectedBuilding: null,
      selectedDepartment: null,
      selectedStaff: null,
      staffData: null,
    };
  },
  watch: {
    selectedBuilding() {
      if (this.selectedBuilding !== null) {
        this.fetchDepartments(this.selectedBuilding);
        this.showDepartmentField = true;
      } else {
        this.showDepartmentField = false;
      }
    },
    selectedDepartment() {
      if (this.selectedDepartment !== null) {
        this.fetchStaff(this.selectedDepartment);
        this.showSelectStaffField = true;
      } else {
        this.showSelectStaffField = false;
      }
    },
    selectedStaff() {
      if (this.selectedStaff !== null) {
        this.fetchStaffDetails(this.selectedStaff);
        // this.showStaffData = true;
      } else {
        this.showStaffData = false;
      }
    },
  },
  computed: {
    staffDataClass() {
      return this.showStaffData && this.staffData.is_active
        ? "text-success"
        : "text-danger";
    },
  },
  methods: {
    fetchAllBuildings() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ALL_BUILDINGS
        )
        .then((res) => {
          const arr = res.data.data;
          arr.forEach((obj) =>
            this.$Methods.renameKey(
              obj,
              "building_name",
              "label",
              "building_id",
              "value"
            )
          );
          this.allBuildings = arr;
        });
    },
    fetchDepartments(id) {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_DEPT_BUILDING_WISE +
            "/" +
            id
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            arr.forEach((obj) =>
              this.$Methods.renameKey(
                obj,
                "department_name",
                "label",
                "dept_id",
                "value"
              )
            );
            this.allDepartments = arr;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    fetchStaff(id) {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_STAFF_DEPT_WISE +
            "/" +
            id +
            "/SUPERVISOR"
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            arr.forEach((obj) =>
              this.$Methods.renameKey(
                obj,
                "staff_firstname",
                "label",
                "staff_id",
                "value"
              )
            );
            this.allStaff = arr;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    fetchStaffDetails(id) {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.STAFF_API +
            "/" +
            id
        )
        .then((res) => {
          if (res.status === 200) {
            this.staffData = res.data.data;
            this.showStaffData = true;
            if (this.staffData.is_active) {
              this.$emit("show-box", true, this.staffData);
            } else {
              this.$emit("show-box", false);

              this.$Methods.errorAlert(
                "This Staff is inactive please activate from manage staff"
              );
            }
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchAllBuildings();
  },
};
</script>
