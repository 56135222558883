<template>
  <div class="col-md-9 mx-auto mt-2">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Add New Sub Resource</h3>
      </template>
      <form class="form-horizontal" @submit.prevent="addSubResourceHandler()">
        <div class="card-body">
          <div class="form-group row">
            <label for="resource name" class="col-sm-3 col-form-label"
              >Sub Resource Name<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="subResourceName"
              />
              <ErrorMessage v-if="subResourceNameError">{{
                subResourceNameError
              }}</ErrorMessage>
            </div>
          </div>
          <div class="form-group row">
            <label for="resource name" class="col-sm-3 col-form-label"
              >Resource Name<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <multi-select
                v-model="resourceName"
                :options="allResourceName"
                :searchable="true"
                placeholder="Select Resource"
              ></multi-select>
              <ErrorMessage v-if="resourceNameError"
                >Please select a valid resource name</ErrorMessage
              >
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-info">
            Add New Sub Resource
          </button>
          <button type="reset" class="btn btn-default float-right">
            Clear
          </button>
        </div>
      </form>
    </base-card>
  </div>
</template>
<script>
import ErrorMessage from "../UI/ErrorMessage.vue";
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import axios from "axios";
export default {
  data() {
    return {
      subResourceName: "",
      resourceName: "",
      subResourceNameError: null,
      resourceNameError: null,
      allResourceName: {},
    };
  },
  components: {
    ErrorMessage,
    AsteriskSymbol,
  },
  methods: {
    addSubResourceHandler() {
      axios
        .post(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.SUB_RESOURCE_API,
          {
            sub_resource_name: this.subResourceName,
            resource_ref_id: this.resourceName,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.$router.push("/viewAllSubResource");
          } else {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
          if (err.response.status === 422) {
            if (err.response.data.errors.sub_resource_name) {
              this.subResourceNameError =
                err.response.data.errors.sub_resource_name[0];
            } else {
              this.subResourceNameError = null;
            }
            if (err.response.data.errors.resource_ref_id) {
              this.resourceNameError =
                err.response.data.errors.resource_ref_id[0];
            } else {
              this.resourceNameError = null;
            }
          }
        });
    },
    getAllResourceName() {
      axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_DISTRIBUTED_RESOURCE
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            arr.forEach((obj) => {
              this.$Methods.renameKeyWithCustomMsg(
                obj,
                "resource_name",
                "label",
                "resource_id",
                "value",
                "in",
                "building_name"
              );
            });
            this.allResourceName = arr;
          } else {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.getAllResourceName();
  },
};
</script>
