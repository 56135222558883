<template>
  <div class="container col-8 mt-2">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Add New Admin</h3>
      </template>
      <template #default>
        <form class="form-horizontal" @submit.prevent="submitHandler()">
          <div class="card-body">
            <div class="form-group row">
              <label for="first-name" class="col-sm-2 col-form-label"
                >Admin Name<AsteriskSymbol
              /></label>
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="adminName"
                  :class="adminNameClass"
                  placeholder="Piyush"
                />
                <ErrorMessage v-if="adminNameError">
                  <p v-for="err in adminNameError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>
            <div class="form-group row">
              <label for="first-name" class="col-sm-2 col-form-label"
                >Admin Email<AsteriskSymbol
              /></label>
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="adminEmail"
                  :class="adminEmailClass"
                  placeholder="piyush@gmail.com"
                />
                <ErrorMessage v-if="adminEmailError">
                  <p v-for="err in adminEmailError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <button
                type="submit"
                class="btn btn-info col-2"
                v-if="!showLoader"
              >
                Add Admin
              </button>
              <loading-screen v-else></loading-screen>
              <button type="reset" class="btn btn-default col-2 ml-auto">
                Clear
              </button>
            </div>
          </div>
        </form>
      </template>
    </base-card>
  </div>
</template>
<script>
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import LoadingScreen from "../shared/LoadingScreen.vue";
import ErrorMessage from "../UI/ErrorMessage.vue";
export default {
  data() {
    return {
      adminName: "",
      adminEmail: "",
      showLoader: false,

      adminNameError: null,
      adminEmailError: null,
    };
  },
  components: {
    AsteriskSymbol,
    LoadingScreen,
    ErrorMessage,
  },
  computed: {
    adminEmailClass() {
      return this.adminEmailError ? "form-control is-invalid" : "form-control";
    },
    adminNameClass() {
      return this.adminNameError ? "form-control is-invalid" : "form-control";
    },
  },
  methods: {
    submitHandler() {
      this.showLoader = true;
      this.axios
        .post(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.ADD_NEW_ADMIN,
          {
            admin_email: this.adminEmail,
            admin_name: this.adminName,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          this.showLoader = false;

          if (res.status === 200) {
            this.$Methods.successAlert(res.data.message);
            this.adminEmail = "";
            this.adminName = "";
            this.adminNameError = null;
            this.adminEmailError = null;
          }
        })
        .catch((err) => {
          this.showLoader = false;
          if (err.response.status === 422) {
            if (err.response.data.errors.admin_name) {
              this.adminNameError = err.response.data.errors.admin_name;
            } else {
              this.adminNameError = null;
            }
            if (err.response.data.errors.admin_email) {
              this.adminEmailError = err.response.data.errors.admin_email;
            } else {
              this.adminEmailError = null;
            }
          } else {
            this.$Methods.toastError(err.response.data.message);
          }
        });
    },
  },
};
</script>
