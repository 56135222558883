<template>
  <div class="card">
    <div class="card-header">
      <slot name="card-header"></slot>
    </div>
    <table class="table table-bordered table-striped" v-if="!isLoading">
      <thead>
        <tr>
          <slot name="table-header"></slot>
        </tr>
      </thead>
      <tbody >
        <slot></slot>
      </tbody>
      
    </table>
    <div class="mx-auto my-5" v-else>
      <loading-screen/>
    </div>
      
  </div>
</template>
<script>
export default{
  props:['isLoading']
}
</script>
