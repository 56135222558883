<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
      <!-- <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/home" class="nav-link">Home</router-link>
      </li> -->
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->

      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="fas fa-user"></i>
          <!-- <span class="badge badge-warning navbar-badge">15</span> -->
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <!-- <span class="dropdown-item dropdown-header">15 Notifications</span> -->
          <div class="dropdown-divider"></div>
          <router-link to="/manageProfile" class="dropdown-item">
            <i class="fas fa-user mr-2"></i> Manage Profile
          </router-link>
          <div class="dropdown-divider"></div>
          <router-link to="/addNewAdmin" class="dropdown-item">
            <i class="fas fa-user-plus mr-2"></i> Add New Admin
          </router-link>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item" @click="logoutHandler()">
            <i class="fas fa-sign-out mr-2"></i> Logout
          </button>
          <div class="dropdown-divider"></div>
        </div>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import axios from "axios";
export default {
  methods: {
    logoutHandler() {
      axios
        .post(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.LOGOUT,
          
        )
        .then((res) => {
          if(res.status === 200){
            this.$Methods.toastSuccess(res.data.message);
            localStorage.clear();
            window.location.href ="/";
          }
        })
        .catch((error) => {
          this.$Methods.toastError(error.response.data.message);
        });
    },
  },
};
</script>

<style></style>
