<template>
  <section class="content p-5">
    <div class="error-page">
      <h2 class="headline text-warning">404</h2>

      <div class="error-content">
        <h3 class="mt-3">
          <i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page
          not found.
        </h3>

        <p class="mt-4">
          We could not find the page you were looking for. Meanwhile, you may
          <router-link to="/home">return to dashboard</router-link>
        </p>

        
      </div>
      <!-- /.error-content -->
    </div>
    <!-- /.error-page -->
  </section>
</template>
