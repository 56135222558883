<template>
  <div class="container">
    <div class="row mt-3">
      <div class="col-md-4 py-2 my-auto">
        <div class="card card-widget widget-user">
          <div class="widget-user-header bg-info">
            <h3 class="widget-user-username" v-if="adminData">
              {{ adminData["name"] }}
            </h3>
            <h5 class="widget-user-desc">Administrator</h5>
          </div>
          <div class="widget-user-image">
            <img
              class="img-circle elevation-2"
              src="../../assets/img/administrator-icon.png"
              alt="User Avatar"
            />
          </div>
          <div class="card-footer">
            <div class="row">
              <p style="text-align: center">Edit/View Profile Here</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <card-layout>
          <h5>View Profile</h5>
          <table class="table table-sm">
            <tr>
              <td>Name</td>
              <td v-if="adminData">{{ adminData["name"] }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td v-if="adminData">{{ adminData["email"] }}</td>
            </tr>
            <tr>
              <td>Account Start-Date :</td>
              <td v-if="adminData">{{ adminData["created_at"] }}</td>
            </tr>
          </table>
        </card-layout>
        <card-layout>
          <div class="row">
            <h6 class="col-3">Create New Admin</h6>
            <div class="col-3 ml-auto">
              <base-button classType="info" @click="this.$router.push('/addNewAdmin')"
                >Add New Admin <i class="fas fa-add"></i
              ></base-button>
            </div>
          </div>
        </card-layout>
        <card-layout>
          <div class="row">
            <h6 class="col-3">Change Password</h6>
            <div class="col-3 ml-auto">
              <base-button classType="warning-g" @click="changePasswordHandler"
                >Change Password <i class="fas fa-retweet"></i
              ></base-button>
            </div>
          </div>
        </card-layout>
        <card-layout>
          <div class="row">
            <h6 class="col-3">Logout</h6>
            <div class="col-3 ml-auto">
              <base-button classType="danger-g" @click="logoutHandler"
                >Logout <i class="fas fa-sign-out"></i
              ></base-button>
            </div>
          </div>
        </card-layout>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      adminData: null,
      passwordVerfication: null,
    };
  },
  methods: {
    logoutHandler() {
      const token = localStorage.getItem("apiToken");
      this.axios
        .post(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.LOGOUT,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            localStorage.clear();
            window.location.href = "/";
          }
        })
        .catch((error) => {
          this.$Methods.toastError(error.response.data.message);
        });
    },
    fetchAdminData() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_TOKEN_STATUS,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.adminData = res.data;
          }
        })
        .catch(() => {
          this.$Methods.toastError("Error in fetching Profile Data");
        });
    },

    async changePasswordHandler() {
      const { value: password } = await Swal.fire({
        title: "Enter your current password",
        input: "password",
        inputLabel: "Your password",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            // return "You need to write something!";
          }
        },
      });
      if (password) {
        this.axios
          .post(
            this.$Constants.BaseUrl +
              this.$Constants.ApiEndpoints.CHECK_PASSWORD,
            {
              password: password,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$token}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              this.passwordVerfication = res.data.data;
              if (res.data.data) {
                this.changePasswordDialog(false);
              } else {
                this.$Methods.errorAlert("Password Verification Failed");
              }
            }
          })
          .catch((err) => {
            this.$Methods.toastError(err.response.data.message);
          });
      }
    },
    async changePasswordDialog(status) {
      if (status) {
        const { value: formValues } = await Swal.fire({
          title: "Enter Your New password",
          html:
            "<label > New Password</label>" +
            '<input id="swal-input1" class="swal2-input" type="password">' +
            "<label>Confirm New Password</label>" +
            '<input id="swal-input2" class="swal2-input" type="password">'+
            "<p class='text-danger'>Both Password Doesn't Match</p>"
            ,
          focusConfirm: false,
          preConfirm: () => {
            var pass =  document.getElementById("swal-input1").value;
            var confirmPass =   document.getElementById("swal-input2").value;
            if(pass === ""){
              this.changePasswordDialog(true)
            }
            else if(pass === confirmPass){
              // return pass
              this.changePassword(pass)
            }
            else{
              this.changePasswordDialog(true)
            }
            
          },
        });
        if (formValues) {
          // this.changePassword(formValues)
        }
      } else {
        const { value: formValues } = await Swal.fire({
          title: "Enter Your New password",
          html:
            "<label > New Password</label>" +
            '<input id="swal-input1" class="swal2-input" type="password" required>' +
            "<label>Confirm New Password</label>" +
            '<input id="swal-input2" class="swal2-input" type="password" required>',
          focusConfirm: false,
          preConfirm: () => {
            var pass =  document.getElementById("swal-input1").value;
            var confirmPass =   document.getElementById("swal-input2").value;
            if(pass === ""){
              this.changePasswordDialog(true)
            }
            else if(pass === confirmPass){
              // return pass
              this.changePassword(pass)

            }
            else{
              this.changePasswordDialog(true)
            }
          },
        });
        if (formValues) {
          console.log(formValues);
          // this.changePassword(formValues)
        }
      }
    },
    changePassword(password){
      // console.log(password)
      this.axios.post(this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.CHANGE_PASSWORD,{
        password:password
      },{
        headers:{
          Authorization:`Bearer ${this.$token}`
        }
      }).then(res=>{
        if(res.status === 200){
          this.$Methods.toastSuccess(res.data.message);
        }
      }).catch(err=>{
        this.$Methods.toastError(err.response.data.message);
      });
    }
  },
  mounted() {
    this.fetchAdminData();
  },
};
</script>
