<template>
  <div class="input-group mb-3 col-3">
    <input
      type="text"
      class="form-control broder"
      :placeholder="placeholder"
      v-model="inputVal"
      @input="this.$emit('change-input', inputVal)"
    />
    <div class="input-group-append">
      <span class="input-group-text"><i class="fas fa-search"></i></span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["placeholder"],
  data() {
    return {
      inputVal: null,
    };
  },
};
</script>
