<template>
  <div class="container">
    <div class="col mt-5" v-if="isLoading && error">
      <LoadingScreen class="mx-auto"></LoadingScreen>
      <br />
      <p style="text-align: center">
        Please Sit and do nothing we are activating your account.
      </p>
    </div>
    <div v-else-if="error" class="col mt-5">
      <h5 style="text-align: center">
        Something Seems wrong please try to
        <router-link to="/login">Login</router-link>
      </h5>
    </div>
    <div class="col mt-5" v-else>
      <h5 style="text-align: center">
        Your account is activated please Login to continue
        <router-link to="/login">Login</router-link>
      </h5>
    </div>
  </div>
</template>
<script>
import LoadingScreen from "../shared/LoadingScreen.vue";
export default {
  data() {
    return {
      isLoading: true,
      error: false,
    };
  },
  props: ["token"],
  components: {
    LoadingScreen,
  },
  methods: {
    activateAccount() {
      this.axios
        .post(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.ACTIVATE_ACCOUNT,
          {
            token: this.token,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.successAlert(res.data.message);
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.$Methods.toastError(err.response.data.message);
          this.error = true;
        });
    },
  },
  mounted() {
    this.activateAccount();
  },
};
</script>
