<template>
  <table-view>
    <template #card-header>
      <div class="row">
        <div class="col">
          Did not Clean Between {{ startDate }} and {{ endDate }}
        </div>
        <div class="col-2 ml-auto">
          <download-excel
            :data="json_data"
            :fields="json_fields"
            class="btn btn-success"
            type="xlsx"
            :name="excelFileName"
          >
            Download Excel
            <i class="fa fa-download"></i>
          </download-excel>
        </div>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>AC Unique Name</th>
      <th>Assigned Caretaker Name</th>
      <th>Phone Number</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr v-for="(notClean, index) in notCleanedData" :key="notClean.ac_id">
        <td>{{ index }}</td>
        <td>{{ notClean.unique_alias }}</td>
        <td>{{ notClean.staff_firstname + " " + notClean.staff_lastname }}</td>
        <td>{{ notClean.staff_mobile }}</td>
        <td>
          <i
            class="fas fa-bell show-pointer"
            style="font-size: 20px; color: red"
            title="Notify"
          ></i>
        </td>
      </tr>
    </template>
  </table-view>
</template>
<script>
import TableView from "../UI/TableView.vue";
export default {
  data() {
    return {
      notCleanedData: {},
      json_data: [],
      json_fields: {
        "AC Name": "unique_alias",
        "First Name": "staff_firstname",
        "Last Name": "staff_lastname",
        "Mobile":"staff_mobile"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  props: ["startDate", "endDate"],
  components: { TableView },
  computed: {
    excelFileName() {
      return (
        "cleaning_not_completed_between_" + this.startDate + "_" + this.endDate
      );
    },
  },
  methods: {
    fetchNotCleanedData() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_NOT_CLEANED_DATA +
            "/" +
            this.startDate +
            "/" +
            this.endDate,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.notCleanedData = res.data.data;
            this.convertJsonToArray()
          } else if (res.status === 204) {
            this.$Methods.toastError("No Data between selected date");
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    convertJsonToArray() {
      for (let index in this.notCleanedData) {
        this.json_data.push(this.notCleanedData[index]);
      }
    },
  },
  mounted() {
    this.fetchNotCleanedData();
  },
};
</script>
