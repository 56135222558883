<template>
  <card-layout>
    <div class="row">
      <h6 style="width: auto">De-Select Buildings</h6>
      <base-button
        class="col-md-1 ml-auto m-2"
        classType="warning-g"
        :disabled="selectedBuilding?.length > 0 ? false : true"
        @click="submitHandler()"
        >UnAssign
      </base-button>
    </div>
    <div class="row">
      <card-layout
        v-for="building in allAssignedBuildings"
        :key="building.building_id"
        class="w-auto m-2 show-pointer"
        :class="
          selectedBuilding.find((build) => build === building.building_id)
            ? 'bg-success'
            : ''
        "
        @click.left="selectBuildingForUnAssign(building)"
      >
        <building-detail :building="building"></building-detail>
      </card-layout>
    </div>
  </card-layout>
</template>
<script>
import BuildingDetail from '../buildings/BuildingDetail.vue';
export default {
  components:{BuildingDetail},
  props: ["staffData"],
  data() {
    return {
      selectedBuilding: [],
      allAssignedBuildings: [],
    };
  },
  methods: {
    fetchAssignedBuildings() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ASSIGNED_BUILDINGS+"/"+this.staffData.staff_id
        )
        .then((res) => {
          if (res.status === 200) {
            this.allAssignedBuildings = res.data.data;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    selectBuildingForUnAssign(building) {
      if (
        this.selectedBuilding.find(
          (buildId) => buildId === building.building_id
        )
      ) {
        this.selectedBuilding = this.selectedBuilding.filter(
          (build) => build !== building.building_id
        );
      } else {
        this.selectedBuilding.push(building.building_id);
      }

    },
    submitHandler() {
      this.axios
        .post(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.UNASSIGN_BUILDING_FROM_STAFF,
          {
            building_ids: this.selectedBuilding,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.selectedBuilding = [];
            this.fetchAssignedBuildings();
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchAssignedBuildings();
  },
};
</script>
