<template>
  <div class="col-md-5 mx-auto mt-5">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Login</h3>
      </template>
      <!-- /.card-header -->
      <!-- form start -->
      <form class="form-horizontal" @submit.prevent="submitHandler">
        <div class="card-body">
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label"
              >Email<AsteriskSymbol
            /></label>
            <div class="col-sm-10">
              <input
                type="email"
                class="form-control"
                id="inputEmail3"
                placeholder="Email"
                v-model="email"
                required
              />
              <ErrorMessage v-if="!isEmailValid">Email is Invalid</ErrorMessage>
            </div>
          </div>
          <div class="form-group row">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              >Password<AsteriskSymbol />
            </label>
            <div class="col-sm-10">
              <input
                type="password"
                class="form-control"
                id="inputPassword3"
                placeholder="Password"
                v-model="password"
                required
              />
              <ErrorMessage v-if="!isPasswordValid"
                >Password is Invalid</ErrorMessage
              >
            </div>
          </div>
          <div class="row">
            <router-link to="/forgotPassword" class="ml-auto col-4" style="text-decoration: underline;">Forgot your password?</router-link>
          </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
          <button type="submit" class="btn btn-info">Sign in</button>
          <button type="reset" class="btn btn-default float-right">
            Clear
          </button>
        </div>
        <!-- /.card-footer -->
      </form>
    </base-card>
  </div>
  <!-- /.card -->
</template>

<script>
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import ErrorMessage from "../UI/ErrorMessage.vue";
import axios from "axios";
import BaseCard from "../UI/BaseCard.vue";
export default {
  name: "LoginPage",
  components: { AsteriskSymbol, ErrorMessage, BaseCard },
  data() {
    return {
      isEmailValid: true,
      isPasswordValid: true,
      email: "",
      password: "",
      remember: false,
    };
  },
  inject: ["userLoggedInStatus"],
  methods: {
    isFieldValid() {
      let flag = true;
      const email = this.$refs.userEmail.value.trim();
      const password = this.$refs.userPassword.value.trim();
      if (email.length === 0) {
        this.isEmailValid = false;
        flag = false;
      }
      if (password.length === 0) {
        this.isPasswordValid = false;
        flag = false;
      }
      return flag;
    },
    submitHandler() {
      axios
        .post(this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.LOGIN, {
          email: this.email,
          password: this.password,
          remembers: this.remember,
        })
        .then((response) => {
          if (response.status === 200) {
            this.userLoggedInStatus = true;
            localStorage.setItem("apiToken", response.data.token);
            this.$userLoggedIn = true;
            this.$router.push("/home");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            // this.$Methods.warningAlert(error.response.data.message);
            this.$Methods.toastWarning(error.response.data.message);
          } else if (error.response.status === 401) {
            this.$Methods.toastError(error.response.data.message);
          } else if (error.response.status === 500) {
            this.$Methods.toastError(error.response.data.message);
          }
        });
    },
  },
};
</script>
