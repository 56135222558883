<template>
  <table-view :isLoading="isLoading">
    <template #card-header>
      <h3 class="card-title">Categories</h3>
      <div class="row">
        <div class="ml-auto col-2">
          <add-new-category
            @on-success="fetchAllCategories"
            key="addCompany"
            unique-key="addCompany"
          />
        </div>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Category</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr
        v-for="(category, index) in allCategories"
        :key="category.category_id"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ category.category_name }}</td>
        <td>
          <span
            @click="editHandler(category.category_id, category.category_name)"
            class="show-pointer"
          >
            <i
              class="fas fa-edit p-2"
              style="color: rgb(255, 153, 0); font-size: 22px"
            ></i>
          </span>
          <span
            @click="deleteHandler(category.category_id)"
            class="show-pointer"
          >
            <i class="fas fa-trash p-2" style="color: red; font-size: 22px"></i>
          </span>
        </td>
      </tr>
    </template>
  </table-view>
</template>
<script>
import TableView from "../UI/TableView.vue";
import AddNewCategory from "./AddNewCategory.vue";
import Swal from "sweetalert2";
export default {
  components: {
    TableView,
    AddNewCategory,
  },
  data() {
    return {
      isLoading: false,
      allCategories: [],
    };
  },
  methods: {
    fetchAllCategories() {
      this.isLoading = true;
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESOURCE_CATEGORY
        )
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.allCategories = res.data.data;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.$Methods.toastError(err.response.data.message);
        });
    },
    editHandler(id, data) {
      this.$router.push(`/editCategory/${id}/${data}`);
    },
    deleteHandler(id) {
      Swal.fire({
        title: "Are you sure?",
        html: "Are you sure you want to delete the selected company",
        icon: "warning",
        allowEnterKey: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(
              this.$Constants.BaseUrl +
                this.$Constants.ApiEndpoints.RESOURCE_CATEGORY +
                `/${id}`
            )
            .then((res) => {
              if (res.status === 200) {
                this.fetchAllCategories();
                Swal.fire(
                  "Deleted Successfully!",
                  "The Company has been deleted successfully.",
                  "success"
                );
              }
            })
            .catch((err) => {
              this.$Methods.toastError(err.response.data.message);
            });
        }
      });
    },
  },
  mounted() {
    this.fetchAllCategories();
  },
};
</script>
