<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Select Caretaker</h3>

        <div class="card-tools">
          <button
            type="button"
            class="btn btn-tool"
            data-card-widget="collapse"
          >
            <i class="fas fa-minus"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row" v-if="showSearchstaff">
          <div class="col-sm-5">
            <div class="my-3">
              <label>Select Building</label>
              <multi-select
                :options="allBuildings"
                :searchable="true"
                placeholder="Select Building"
                v-model="selectedBuilding"
              ></multi-select>
            </div>
            <div class="my-3" v-if="showDepartmentField">
              <label>Select Department</label>
              <multi-select
                :options="allDepartments"
                :searchable="true"
                placeholder="Select Department"
                v-model="selectedDepartment"
              ></multi-select>
            </div>
            <div class="my-3" v-if="showSelectStaffField">
              <label>Select Caretaker</label>
              <multi-select
                :options="allStaff"
                :searchable="true"
                placeholder="Select Caretaker"
                v-model="selectedStaff"
              >
                <template v-slot:singlelabel="{ value }">
                  <div class="multiselect-single-label">
                    {{ value.staff_unique_id }} | {{ value.label }}
                    {{ value.staff_lastname }}
                  </div>
                </template>
              </multi-select>
            </div>
          </div>

          <div class="col-sm-5 ml-5">
            <p class="text-decoration-underline" style="font-weight: 800">
              Caretaker Details
            </p>
            <table class="table table-bordered" v-if="showStaffData">
              <tr>
                <td>Name :</td>
                <td :class="staffDataClass">
                  {{ staffData.staff_firstname }} {{ staffData.staff_lastname }}
                </td>
              </tr>
              <tr>
                <td>Email :</td>
                <td :class="staffDataClass">{{ staffData.staff_email }}</td>
              </tr>
              <tr>
                <td>Phone :</td>
                <td :class="staffDataClass">{{ staffData.staff_mobile }}</td>
              </tr>
              <tr>
                <td>Unique No :</td>
                <td :class="staffDataClass">
                  {{ staffData.staff_unique_id }}
                </td>
              </tr>
              <tr>
                <td>Active Status :</td>
                <td :class="staffDataClass">
                  {{ staffData.is_active ? "Active" : "InActive" }}
                </td>
              </tr>
              <tr>
                <td>AC Assign Count :</td>
                <td
                  class="show-pointer fake-link"
                  :class="staffDataClass"
                  title="Click to See the details of AC"
                  @click="showAssignedACData"
                >
                  {{ acCount }}
                </td>
              </tr>
            </table>
            <loading-screen v-else></loading-screen>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.fake-link:hover {
  text-decoration: underline;
}
.swal-inc-width {
  width: 1200px !important;
}
</style>
<script>
import LoadingScreen from "../shared/LoadingScreen.vue";
import Swal from "sweetalert2";
export default {
  components: {
    LoadingScreen,
  },
  data() {
    return {
      showSearchstaff: true,
      showDepartmentField: false,
      showSelectStaffField: false,
      showStaffData: false,
      acCount: 0,
      assignedACData: null,

      allBuildings: {},
      allDepartments: {},
      allStaff: {},

      selectedBuilding: null,
      selectedDepartment: null,
      selectedStaff: null,
      staffData: null,
    };
  },
  computed: {
    staffDataClass() {
      return this.showStaffData && this.staffData.is_active
        ? "text-success"
        : "text-danger";
    },
  },
  // emits:['show-assign-box'],
  methods: {
    renameKey(obj, oldKey, newKey, oldKey1, newKey1) {
      obj[newKey] = obj[oldKey];
      obj[newKey1] = obj[oldKey1];
      delete obj[oldKey];
      delete obj[oldKey1];
    },
    changeViewStatus() {
      this.showSearchStaff = !this.showSearchStaff;
    },
    getAllBuildings() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ALL_BUILDINGS,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            arr.forEach((obj) =>
              this.renameKey(
                obj,
                "building_name",
                "label",
                "building_id",
                "value"
              )
            );
            this.allBuildings = arr;
          }
        })
        .catch(() => {
          this.$Methods.toastError("Error in fetching All Buildings");
        });
    },
    fetchDepartments(id) {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_DEPT_BUILDING_WISE +
            "/" +
            id,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            arr.forEach((obj) =>
              this.renameKey(
                obj,
                "department_name",
                "label",
                "dept_id",
                "value"
              )
            );
            this.allDepartments = arr;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    fetchStaff(id) {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_STAFF_DEPT_WISE +
            "/" +
            id +
            "/CARETAKER",
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            arr.forEach((obj) =>
              this.renameKey(
                obj,
                "staff_firstname",
                "label",
                "staff_id",
                "value"
              )
            );
            this.allStaff = arr;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    fetchStaffDetails(id) {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.STAFF_API +
            "/" +
            id,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.staffData = res.data.data;
            this.showStaffData = true;
            if (this.staffData.is_active) {
              this.$emit("show-box", true, this.staffData);
            } else {
              this.$emit("show-box", false);

              this.$Methods.errorAlert(
                "This Staff is inactive please activate from manage staff"
              );
            }
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    fetchStaffAssignACCount(id) {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ASSIGNED_AC_COUNT_STAFF +
            "/" +
            id,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.acCount = res.data.data;
          }
        })
        .catch(() => {
          this.$Methods.toastError("Error in Fetching Assign Count");
        });
    },
    fecthAssignedACData(id) {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ASSIGNED_AC_DATA_LIST +
            "/" +
            id,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.assignedACData = res.data.data;
          } else {
            this.assignedACData = null;
          }
        })
        .catch(() => {
          // this.assignedACData = {}
          this.$Methods.toastError("Error in Fetching Assign AC Data");
        });
    },
    showAssignedACData() {
      if (this.assignedACData) {
        let swal_html = `<table class='table'>
          <tr>
            <th>Model No</th>
            <th>Capacity</th>
            <th>AC Type</th>
            <th>Building Name</th>
            <th>Resource</th>
            <th>Sub Resource</th>
          </tr>`;
        for (const ac of this.assignedACData) {
          swal_html += `<tr>
              <td>${ac.model_number}</td>
              <td>${ac.ac_capacity}</td>
              <td>${ac.ac_type}</td>
              <td>${ac.building_name}</td>
              <td>${ac.resource_name}</td>
              <td>${ac.sub_resource_name}</td>
              </tr>`;
        }
        swal_html += `</table>`;
        Swal.fire({
          html: swal_html,
          width: 1500,
        });
      } else {
        this.$Methods.toastWarning("No Assigned AC data found");
      }

    },
  },
  watch: {
    selectedBuilding() {
      if (this.selectedBuilding !== null) {
        this.showDepartmentField = true;
        this.fetchDepartments(this.selectedBuilding);
      } else {
        this.showDepartmentField = false;
      }
    },
    selectedDepartment() {
      if (this.selectedDepartment !== null) {
        this.showSelectStaffField = true;
        this.fetchStaff(this.selectedDepartment);
      } else {
        this.showSelectStaffField = false;
      }
    },

    selectedStaff() {
      if (this.selectedStaff !== null) {
        this.fetchStaffDetails(this.selectedStaff);
        this.fetchStaffAssignACCount(this.selectedStaff);
        this.fecthAssignedACData(this.selectedStaff);
      }
    },
  },
  mounted() {
    this.getAllBuildings();
  },
};
</script>
