<template>
  <card-layout>
    <div class="row">
      <h6 style="width: auto">Un Assign AC</h6>
      <base-button
        class="col-md-1 ml-auto m-2"
        classType="warning-g"
        :disabled="selectedAc.length > 0 ? false : true"
        @click="submitHandler()"
        >UnAssign
      </base-button>
    </div>
    <div v-for="building in allBuildings" :key="building.building_id">
      <card-layout v-if="allAcData[building.building_id]">
        <div class="row">
          <h6 style="width: auto">{{ building.building_name }}</h6>
          <base-button
            class="col-md-1 ml-auto"
            @click="buildingVisibilityHandler(building.building_id)"
            ><i class="fas fa-eye"></i
          ></base-button>
        </div>
        <hr />
        <div v-if="allAcData[building.building_id]" class="row">
          <card-layout
            v-for="ac in allAcData[building.building_id]"
            title="left click to select / right click to see the data"
            :key="ac.ac_id"
            class="w-auto m-2 show-pointer"
            :class="ac.is_assigned ? 'bg-success' : ''"
            @click.right="showACData($event, building.building_id, ac.ac_id)"
            @click.left="selectAc(building.building_id, ac.ac_id)"
          >
            <a-c-detail :ac="ac"></a-c-detail>
          </card-layout>
        </div>
        <p v-else class="text-danger">No AC Assigned in this Building</p>
      </card-layout>
    </div>
  </card-layout>
</template>
<script>
import ACDetail from "../air_conditioner/ACDetail.vue";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      showAssignBox: false,

      allBuildings: {},

      allAcData: {},

      selectedAc: [],
    };
  },
  props: ["staffData"],
  components: {
    ACDetail,
  },
  watch: {
    showAssignBox() {
      if (this.showAssignBox === true) {
        this.fetchAllBuildings();
        // this.fetchAssignedACData();
      }
    },
    staffData(){

      if(this.staffData){
        this.fetchAssignedACData()
      }
    }
  },
  methods: {
    showACData(e, buildId, acId) {
      e.preventDefault();
      const data = this.allAcData[buildId].find((ac) => ac.ac_id === acId);
      let swal_html = `
        <h4>AC details</h4>
        <table class="table">
          <tr>
            <td style="text-align:left">Model no.</td>
            <td style="text-align:left">${data.model_number}</td>
          </tr>
          <tr>
            <td style="text-align:left">Outdoor Model no.</td>
            <td style="text-align:left">${data.outdoor_unit_model}</td>
          </tr>
          <tr>
            <td style="text-align:left">Building</td>
            <td style="text-align:left">${data.building_name}</td>
          </tr>
          <tr>
            <td style="text-align:left">Resource</td>
            <td style="text-align:left">${data.resource_name}</td>
          </tr>
          <tr>
            <td style="text-align:left">Sub Resource</td>
            <td style="text-align:left">${data.sub_resource_name}</td>
          </tr>
          <tr>
            <td style="text-align:left">AC Type</td>
            <td style="text-align:left">${data.ac_type}</td>
          </tr>
          <tr>
            <td style="text-align:left">Installation date</td>
            <td style="text-align:left">${data.installation_at}</td>
          </tr>
          <tr>
            <td style="text-align:left">Warranty end date</td>
            <td style="text-align:left">${data.warranty_end_at}</td>
          </tr>
          </table>`;
      Swal.fire({
        html: swal_html,
      });
    },

    addNewFieldInData(obj, field, data) {
      obj[field] = data;
    },

    buildingVisibilityHandler(id) {
      const buildData = this.allBuildings.find(
        (building) => building.building_id === id
      );
      buildData.isVisible = !buildData.isVisible;
    },

    fetchAllBuildings() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ALL_BUILDINGS,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            this.allBuildings = arr;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },

    fetchAssignedACData() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_ASSIGNED_AC +
            "/" +
            this.staffData.staff_id,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.allAcData = res.data.data;
          } else if (res.status === 204) {
            this.$Methods.warningAlert("No Assigned AC found to this Caretaker!!");
            this.allAcData = {};
          }
        })
        .catch((err) => {
          this.$Methods.errorAlert(err.response.data.message);
        });
    },

    selectAc(buildId, acId) {
      let ac = this.allAcData[buildId].find((ac) => ac.ac_id === acId);
      ac.is_assigned = !ac.is_assigned;
      if (this.selectedAc.find((ac) => ac.ac_id === acId)) {
        this.selectedAc = this.selectedAc.filter(
          (acData) => acData.ac_id !== acId
        );
      } else {
        this.selectedAc.push({ ac_id: acId });
      }
    },
    submitHandler() {
      this.axios
        .post(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.UNASSIGN_AC,
          {
            staff_id: this.staffData.staff_id,
            ac_ids: this.selectedAc,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.successAlert(res.data.message);
            this.fetchAssignedACData();
            this.selectedAc = [];
          }
        })
        .catch((err) => {
          this.$Methods.errorAlert(err.response.data.message);
          this.fetchAssignedACData();
          this.selectedAc = [];
        });
    },
  },
  mounted() {
    this.fetchAllBuildings();
    this.fetchAssignedACData();
  },
};
</script>
