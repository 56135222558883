<template>
  <table-view :isLoading="isLoading">
    <template #card-header>
      <h3 class="card-title">Departments</h3>
      <div class="col-2 ml-auto">
        <router-link to="/addNewDept" class="btn btn-block bg-gradient-success">
          Add New
        </router-link>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Department</th>
      <th>Building</th>
      <th>Created By</th>
      <th>Actions</th>
    </template>
    <template #default>
        <tr v-for="(dept, index) in allDepartments" :key="dept.dept_id">
          <td>{{ index + 1 }}</td>
          <td>{{ dept.department_name }}</td>
          <td>{{ dept.building_name }}</td>
          <td>{{ dept.name }}</td>
          <td>
            <span @click="editHandler(dept.dept_id)" class="show-pointer">
              <i
                class="fas fa-edit p-2"
                style="color: rgb(255, 153, 0); font-size: 22px"
              ></i>
            </span>
            <span @click="deleteHandler(dept.dept_id)" class="show-pointer"
              ><i
                class="fas fa-trash p-2"
                style="color: red; font-size: 22px"
              ></i>
            </span>
          </td>
        </tr>
    </template>
  </table-view>
  <!-- <div class="mx-auto " style="width: min-content;" v-else>
    <loading-screen />
  </div> -->
</template>

<script>
import TableView from "../UI/TableView.vue";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      isLoading: false,
      allDepartments: {},
    };
  },
  components: {
    TableView,
  },
  methods: {
    fetchAllDepartments() {
      this.isLoading = true;
      axios
        .get(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.DEPARTMENT_API,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.allDepartments = res.data.data;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.$Methods.toastError(err.response.data.message);
        });
    },
    editHandler(id) {
      this.$router.push(`/editDept/${id}`);
    },
    deleteHandler(id) {
      const confirm = this.$Methods.deleteConfirmDialog(
        "Are you sure you want to delete"
      );
      confirm.fire().then((res) => {
        if (res.isConfirmed) {
          this.axios
            .delete(
              this.$Constants.BaseUrl +
                this.$Constants.ApiEndpoints.DEPARTMENT_API +
                "/" +
                id,
              {
                headers: {
                  Authorization: `Bearer ${this.$token}`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                this.$Methods.toastSuccess(res.data.message);
                this.fetchAllDepartments();
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
              }
            })
            .catch((err) => {
              this.$Methods.toastError(err.response.data.message);
            });
        }
      });
    },
  },
  mounted() {
    this.fetchAllDepartments();
  },
};
</script>
