<template>
  <select-staff @show-box="showBoxHandler"></select-staff>
  <show-caretaker-clean-history
    v-if="showDetailBox"
    :caretakerData="caretakerData"
  ></show-caretaker-clean-history>
</template>
<script>
import SelectStaff from "../staff/SelectStaff.vue";
import ShowCaretakerCleanHistory from "./ShowCaretakerCleanHistory.vue";
export default {
  components: {
    SelectStaff,
    ShowCaretakerCleanHistory,
  },
  data() {
    return {
      showDetailBox: false,
      caretkerData: null,
    };
  },
  methods: {
    showBoxHandler(status, caretaker) {
      this.showDetailBox = status;
      this.caretakerData = caretaker;
    },
  },
};
</script>
