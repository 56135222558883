<template>
  <table-view>
    <template #card-header>
      <h3 class="card-title">Recycle Staff Data</h3>
      <div class="row">
        <search-input
          class="ml-auto"
          placeholder="Fname, Mobile, Unique Id"
          v-model="searchParam"
          @input="fetchDeletedStaff()"
          @change-input="changeSearchVal"
        ></search-input>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Staff Name</th>
      <th>Phone</th>
      <th>Role</th>
      <th>Is Active</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr v-for="(staff, index) in allDeletedStaff" :key="staff.staff_id">
        <td>{{ index + 1 }}</td>
        <td>
          {{ getStaffName(staff) }}
        </td>

        <td>{{ staff.staff_mobile }}</td>
        <td>{{ staff.role }}</td>
        <td
          v-if="staff.is_active"
          class="text-success"
          title="Change the Status to Inactive"
        >
          Active
        </td>
        <td v-else class="text-danger" title="Change the Status to Active">
          In Active
        </td>
        <td>
          <span @click="recycleHandler(staff.staff_id)" class="show-pointer">
            <i
              class="fa-solid fa-recycle p-2"
              style="color: rgb(12 154 49); font-size: 22px"
              title="Recycle"
            ></i>
          </span>
        </td>
      </tr>
    </template>
  </table-view>
  <custom-pagination
    :prev-page="prevPage"
    :next-page="nextPage"
    @on-update="paginationDataUpdate"
  ></custom-pagination>
</template>
<script>
import TableView from "../UI/TableView.vue";
import SearchInput from "../UI/SearchInput.vue";
import CustomPagination from "../shared/pagination/CustomPagination.vue";
import debounce from "lodash.debounce";
export default {
  components: { TableView, SearchInput, CustomPagination },
  data() {
    return {
      allDeletedStaff: [],
      searchParam: "",
      currentPage: 1,
      pageSize: 5,
      prevPage: false,
      nextPage: false,
    };
  },
  methods: {
    changeSearchVal(input) {
      this.searchParam = input;
    },
    paginationDataUpdate(currentPage, pageSize) {
      this.currentPage = currentPage;
      this.pageSize = pageSize;
      this.fetchDeletedStaff();
    },
    fetchDeletedStaff: debounce(function () {
      const searchBody = {
        size: this.pageSize,
        page: this.currentPage,
        searchData: { search_str: this.searchParam },
      };
      this.axios
        .post(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_DELETED_STAFF,
          searchBody
        )
        .then((res) => {
          if (res.status === 200) {
            this.allDeletedStaff = res.data.data;
            this.prevPage = res.data.prevPage;
            this.nextPage = res.data.nextPage;
          } else {
            this.prevPage = false;
            this.nextPage = false;
            this.allDeletedStaff = [];
            this.$Methods.toastWarning("No Data Found");
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },300),
    getStaffName(staff) {
      if (staff.staff_middlename) {
        return (
          staff.staff_firstname +
          " " +
          staff.staff_middlename +
          " " +
          staff.staff_lastname
        );
      } else {
        return staff.staff_firstname + " " + "---" + " " + staff.staff_lastname;
      }
    },
    recycleHandler(id) {
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESTORE_STAFF +
            "/" +
            id
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.fetchDeletedStaff();
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchDeletedStaff();
  },
};
</script>
