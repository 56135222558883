<template>
  <select-staff @show-box="showAssignBoxHandler"></select-staff>
  <div class="container">
    <card-layout>
      <div class="row">
        <div class="text-center">
          <base-button
            class="col-2 m-3"
            :classType="selectedCmp === 'AssignAC' ? 'info' : 'outline-info'"
            @click="selectedComponent('AssignAC')"
            >Assign</base-button
          >
          <base-button
            class="col-2 m-3"
            :classType="selectedCmp === 'UnAssignAC' ? 'info' : 'outline-info'"
            @click="selectedComponent('UnAssignAC')"
            >Un-Assign</base-button
          >
        </div>
      </div>
    </card-layout>
  </div>
  <keep-alive>
    <div v-if="showAssignBox">
      <component :is="selectedCmp" :staffData="staffData"></component>
    </div>
  </keep-alive>
</template>
<script>
import SelectStaff from "./SelectStaff.vue";
import AssignAC from "./AssignAC.vue";
import UnAssignAC from "./UnAssignAC.vue";
export default {
  data() {
    return {
      selectedCmp: "AssignAC",
      showAssignBox: false,
      staffData: null,
    };
  },
  components: {
    SelectStaff,
    AssignAC,
    UnAssignAC,
  },

  methods: {
    selectedComponent(cmp) {
      this.selectedCmp = cmp;
    },
    showAssignBoxHandler(status, staff) {
      this.showAssignBox = status;
      this.staffData = staff;
    },
  },
};
</script>
