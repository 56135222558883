<template>
  <div class="row container mx-auto mt-5">
    <div class="dashboard-container">
      <h1>Welcome..... This is Guest Dashboard</h1>
      <p class="login-message" v-if="!this.$userLoggedIn">Please login to continue.</p>
      <router-link to="/login" class="login-link"  v-if="!this.$userLoggedIn">Login</router-link>
    </div>
  </div>
</template>
<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.dashboard-container {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-message {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.login-link {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}
</style>
