import { start } from '@popperjs/core';
<template>
  <section class="container">
    <card-layout>
      <div class="form-group row">
        <label for="start-date" class="col-sm-2 col-form-label"
          >Start Date :</label
        >
        <div class="col-sm-3">
          <input
            type="date"
            v-model="startDate"
            class="form-control"
            :max="new Date().toISOString().split('T')[0]"
            @input="console.log('change')"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="end-date" class="col-sm-2 col-form-label">End Date :</label>
        <div class="col-sm-3">
          <input type="date" v-model="endDate" class="form-control" />
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="text-center">
          <base-button
            class="col-2 m-3"
            :classType="selectedCmp === 'completed-cleaning' ? 'info' : 'outline-info'"
            @click="selectedComponent('completed-cleaning')"
            >Completed</base-button
          >
          <base-button
            class="col-2 m-3"
            :classType="selectedCmp === 'not-completed-cleaning' ? 'info' : 'outline-info'"
            @click="selectedComponent('not-completed-cleaning')"
            >Not-Completed</base-button
          >
        </div>
      </div>
    </card-layout>
  </section>
  <component
    :is="selectedCmp"
    :startDate="startDate"
    :endDate="endDate"
  ></component>
</template>
<script>
import CompletedCleaning from "./CompletedCleaning.vue";
import NotCompletedCleaning from "./NotCompletedCleaning.vue";
export default {
  data() {
    return {
      startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 7).toISOString().split("T")[0],
      endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0],
      selectedCmp: "completed-cleaning",
    };
  },
  components: {
    CompletedCleaning,
    NotCompletedCleaning,
  },
  methods: {
    selectedComponent(cmp) {
      this.selectedCmp = cmp;
    },
  },
};
</script>
<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
