<template>
  <div class="col-md-9 mx-auto mt-2">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Edit AC Company</h3>
      </template>
      <form class="form-horizontal" @submit.prevent="submitHandler()">
        <div class="card-body">
          <div class="form-group row">
            <label for="ac_model" class="col-sm-3 col-form-label"
              >Company Name<asterisk-symbol></asterisk-symbol></label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="compName"
              />
              <ErrorMessage v-if="nameError">{{
                nameError
              }}</ErrorMessage>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-info">Edit</button>
        </div>
      </form>
    </base-card>
  </div>
</template>
<script>
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
export default {
  props: [ "companyName","companyId"],
  emits: ["on-success"],
  components: {  AsteriskSymbol },
  data() {
    return {
      compName: this.companyName,
    };
  },
  methods: {
    submitHandler() {
      const formBody = { company_name: this.compName };
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.AC_COMPANY +
            `/${this.companyId}`,
          formBody
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
};
</script>
