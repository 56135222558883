<template>
  <table-view :isLoading="isLoading">
    <template #card-header>
      <h3 class="card-title">All Resources</h3>
      <div class="row">
        <search-input
          class="ml-auto"
          placeholder="Name, Building, Category"
          v-model="searchParam"
          @input="searchResource()"
          @change-input="changeSearchVal"
        ></search-input>
      <div class="col-2">
        <router-link
          to="/addNewResource"
          class="btn btn-block bg-gradient-success"
        >
          Add New
        </router-link>
      </div>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Resource Name</th>
      <th>Building</th>
      <th>Type</th>
      <th>Category</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr
        v-for="(resource, index) in resourcesData"
        :key="resource.resource_id"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ resource.resource_name }}</td>
        <td>{{ resource.building_name }}</td>
        <td>{{ resource.resource_type }}</td>
        <td>{{ resource.category_name }}</td>
        <td>
          <span @click="editHandler(resource.resource_id)" class="show-pointer">
            <i
              class="fas fa-edit p-2"
              style="color: rgb(255, 153, 0); font-size: 22px"
            ></i>
          </span>
          <span
            @click="deleteHandler(resource.resource_id)"
            class="show-pointer"
            ><i
              class="fas fa-trash p-2"
              style="color: red; font-size: 22px"
            ></i>
          </span>
        </td>
      </tr>
    </template>
  </table-view>
  <custom-pagination
    :prev-page="prevPage"
    :next-page="nextPage"
    :size="pageSize"
    @on-update="paginationDataUpdate"
  />
</template>
<script>
import TableView from "../UI/TableView.vue";
import axios from "axios";
import CustomPagination from "../shared/pagination/CustomPagination.vue";
import SearchInput from "../UI/SearchInput.vue";
import debounce from "lodash.debounce";
export default {
  data() {
    return {
      isLoading: false,
      resourcesData: {},
      currentPage: 1,
      pageSize: 20,
      prevPage: false,
      nextPage: false,
      searchParam: ""
    };
  },
  components: {
    TableView,
    CustomPagination,
    SearchInput
  },
  methods: {
    changeSearchVal(input) {
      this.searchParam = input;
    },
    editHandler(id) {
      this.$router.push(`/editResource/${id}`);
    },
    deleteHandler(id) {
      axios
        .delete(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESOURCE_API +
            `/${id}`
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.searchResource();
          } else if (res.status === 500) {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.$Methods.toastError(err.response.data.message);
          } else if (err.response.status === 409) {
            this.$Methods.errorAlert(
              "This data is Connected to some other data please remove that and try again!!"
            );
          }
        });
    },
    paginationDataUpdate(currentPage, pageSize) {
      this.currentPage = currentPage;
      this.pageSize = pageSize;
      this.searchResource();
    },
    searchResource: debounce(function () {
      let searchBody = {
        page: this.currentPage,
        size: this.pageSize,
        searchData: {
          search_str: this.searchParam,
        },
      };
      this.axios
        .post(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.SEARCH_RESOURCE,
          searchBody
        )
        .then((res) => {
          if (res.status === 200) {
            this.resourcesData = res.data.data;
            this.prevPage = res.data.prevPage;
            this.nextPage = res.data.nextPage;
          } else if (res.status === 204) {
            this.resourcesData = {};
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    }, 300),
  },

  async mounted() {
    this.searchResource();
  },
};
</script>
