<template>
  <backdrop-dialog-close-success
    btn-class="btn btn-success"
    @onSuccess="onSuccess"
    :key="uniqueKey"
    :uniqueKey="uniqueKey"
  >
    <template #btn-title>Add New</template>
    <template #dialog-header>Add New Category</template>
    <template #default>
      <form>
        <div class="mb-3">
          <label for="companyName" class="form-label">Category Name</label
          ><asterisk-symbol />
          <input
            type="text"
            class="form-control"
            id="categoryName"
            v-model="categoryName"
          />
        </div>
      </form>
    </template>
  </backdrop-dialog-close-success>
</template>
<script>
import BackdropDialogCloseSuccess from "../UI/BackdropDialogCloseSuccess.vue";
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
export default {
  components: {
    BackdropDialogCloseSuccess,
    AsteriskSymbol,
  },
  emits:['on-success'],
  data() {
    return {
      categoryName: "",
    };
  },
  props:['uniqueKey'],
  methods: {
    onSuccess(modalInstance) {
      const formData = { category_name: this.categoryName };
      this.axios
        .post(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESOURCE_CATEGORY,
          formData
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res)
            this.$emit('on-success')
            this.$Methods.toastSuccess(res.data.message);
            modalInstance.hide();
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });

      modalInstance.hide();
    },
  },
};
</script>
