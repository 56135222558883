<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleStaff' }"
        @click="handleComponentChange('RecycleStaff')"
        >Staff</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleAC' }"
        @click="handleComponentChange('RecycleAC')"
        >Air Conditioner</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleResource' }"
        @click="handleComponentChange('RecycleResource')"
        >Resource</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleSubRes' }"
        @click="handleComponentChange('RecycleSubRes')"
        >Sub Resource</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleBuilding' }"
        @click="handleComponentChange('RecycleBuilding')"
        >Building</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleDept' }"
        @click="handleComponentChange('RecycleDept')"
        >Department</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link show-pointer"
        :class="{ active: componentName === 'RecycleCompany' }"
        @click="handleComponentChange('RecycleCompany')"
        >Company</a
      >
    </li>
  </ul>
  <keep-alive>
    <component :is="componentName"></component>
  </keep-alive>
</template>
<script>
import RecycleStaff from "./RecycleStaff.vue";
export default {
  components: { RecycleStaff },
  data() {
    return {
      componentName: "RecycleStaff",
    };
  },
  methods: {
    handleComponentChange(cmpName) {
      this.componentName = cmpName;
    },
  },
};
</script>
