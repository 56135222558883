<template>
  <div class="row">
    {{ ac.resource_name }}
    <span v-if="ac.sub_resource_name" style="width: auto"
      ><i class="fas fa-long-arrow-right"></i
    ></span>
    {{ ac.sub_resource_name }}
  </div>
  <hr />
  <div class="row">
    {{ ac.company_name }} | {{ ac.ac_type }} | {{ ac.model_number }}
  </div>
</template>
<script>
export default {
  props: ["ac"],
};
</script>
