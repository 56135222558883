<template>
  <section class="content">
    <!--todo needs heavy refactoring  -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Buildings</h3>
              <div class="col-2 ml-auto">
                <router-link
                  to="/addNewBuilding"
                  class="btn btn-block bg-gradient-success"
                >
                  Add New
                </router-link>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body" v-if="!isLoading">
              <table id="example1" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Building</th>
                    <th>Alias</th>
                    <th>Created By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(building, index) in allBuildingData"
                    :key="building.building_id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ building.building_name }}</td>
                    <td>{{ building.alias }}</td>
                    <td>{{ building.name }}</td>
                    <td>
                      <span
                        @click="editHandler(building.building_id)"
                        class="show-pointer"
                      >
                        <i
                          class="fas fa-edit p-2"
                          style="color: rgb(255, 153, 0); font-size: 22px"
                        ></i>
                      </span>
                      <span
                        @click="deleteHandler(building.building_id)"
                        class="show-pointer"
                        ><i
                          class="fas fa-trash p-2"
                          style="color: red; font-size: 22px"
                        ></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-auto my-5" v-else>
              <loading-screen></loading-screen>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
// const token = localStorage.getItem("apiToken");
export default {
  data() {
    return {
      isLoading: false,
      allBuildingData: {},
    };
  },
  methods: {
    fetchAllData() {
      this.isLoading = true;
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ALL_BUILDINGS
        )
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.allBuildingData = res.data.data;
          } else if (res.status === 204) {
            this.$Methods.toastAlert("No Building found Please Add Some");
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.$Methods.toastError(err.response.data.message);
        });
    },
    deleteHandler(id) {
      const token = localStorage.getItem("apiToken");
      axios
        .delete(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.DELETE_BUILDING +
            "/" +
            id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.fetchAllData();
          }
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.$Methods.errorAlert(
              "This data is Connected to some other data please remove that and try again!!"
            );
          } else {
            this.$Methods.toastError("Something Went wrong");
          }
        });
    },
    async editHandler(id) {
      this.$router.push("/editBuilding/" + id);
    },
  },
  mounted() {
    this.fetchAllData();
  },
};
</script>
