<template>
  <div class="container col-7 mt-5">
    <base-card cardClass="card card-info">
      <template #card-header> Create New Password </template>
      <template #default>
        <form class="form-horizontal" @submit.prevent="submitHandler()">
          <div class="card-body">
            <div class="form-group row">
              <label for="first-name" class="col-sm-2 col-form-label"
                >Password<AsteriskSymbol
              /></label>
              <div class="col-sm-10">
                <input
                  type="password"
                  v-model="password"
                  :class="passwordClass"
                />
                <ErrorMessage v-if="passwordError">
                  <p v-for="err in passwordError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>
            <div class="form-group row">
              <label for="first-name" class="col-sm-3 col-form-label"
                >Confirm Password<AsteriskSymbol
              /></label>
              <div class="col-sm-9">
                <input
                  type="password"
                  v-model="confirmPassword"
                  :class="confirmPasswordClass"
                />
                <ErrorMessage v-if="confirmPasswordError">
                  <p v-for="err in confirmPasswordError" :key="err">
                    {{ err }}
                  </p>
                </ErrorMessage>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <button type="submit" class="btn btn-info col-3">
                Update Password
              </button>
              <button type="reset" class="btn btn-default col-2 ml-auto">
                Clear
              </button>
            </div>
          </div>
        </form>
      </template>
    </base-card>
  </div>
</template>
<script>
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import ErrorMessage from "../UI/ErrorMessage.vue";
export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordError: null,
      confirmPasswordError: null,
    };
  },
  props: ["token"],
  components: {
    AsteriskSymbol,
    ErrorMessage,
  },
  computed: {
    passwordClass() {
      return this.passwordError ? "form-control is-invalid" : "form-control";
    },
    confirmPasswordClass() {
      return this.confirmPasswordError
        ? "form-control is-invalid"
        : "form-control";
    },
  },
  methods: {
    submitHandler() {
        this.axios.post(this.$Constants.BaseUrl+this.$Constants.ApiEndpoints.EDIT_FORGOT_PASSWORD,{
            password:this.password,
            confirmPassword:this.confirmPassword,
            token:this.token
        }).then(res=>{
            if(res.status === 200){
                this.$Methods.successAlert(res.data.message)
            }
        }).catch(err=>{
            this.$Methods.errorAlert(err.response.data.message);
        });
    },
  },
};
</script>
