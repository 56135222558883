<template>
  <div class="col-12">
    <div class="row">
      <div class="col-md-1 ms-auto">
        <select class="form-select w-100 m-1" v-model="pageSize">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="20">20</option>
          <option :value="50">50</option>
          <option :value="100">100</option>
          <option :value="200">200</option>
        </select>
      </div>
      <div class="col-md-3 mb-2">
        <button
          class="btn btn-secondary m-1"
          :class="!prevPage ? 'disabled' : ''"
          @click="clickPrevPage()"
        >
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        <button class="btn btn-secondary m-1">{{ currentPage }}</button>
        <button
          class="btn btn-secondary m-1"
          :class="!nextPage ? 'disabled' : ''"
          @click="clickNextPage()"
        >
          <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["nextPage", "prevPage", "size"],
  data() {
    return {
      currentPage: 1,
      pageSize: this.size ? this.size : 5,
    };
  },
  watch: {
    pageSize() {
      this.currentPage = 1;
      this.$emit("on-update", 1, this.pageSize);
    },
  },
  methods: {
    clickNextPage() {
      this.currentPage++;
      this.$emit("on-update", this.currentPage, this.pageSize);
    },
    clickPrevPage() {
      this.currentPage--;
      this.$emit("on-update", this.currentPage, this.pageSize);
    },
  },
};
</script>
