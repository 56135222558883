<template>
  <div class="col-md-9 mx-auto mt-2">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Edit Category</h3>
      </template>
      <form class="form-horizontal" @submit.prevent="submitHandler()">
        <div class="card-body">
          <div class="form-group row">
            <label for="ac_model" class="col-sm-3 col-form-label"
              >Category Name<asterisk-symbol></asterisk-symbol
            ></label>
            <div class="col-sm-9">
              <input type="text" class="form-control" v-model="categoryName" />
              <ErrorMessage v-if="acModelError">{{
                categoryError
              }}</ErrorMessage>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-info">Edit</button>
        </div>
      </form>
    </base-card>
  </div>
</template>
<script>
export default {
  props: ["categoryId", "category"],
  data() {
    return {
      categoryName: this.category,
    };
  },
  methods:{
    submitHandler(){
        const formBody = { category_name: this.categoryName };
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESOURCE_CATEGORY +
            `/${this.categoryId}`,
          formBody
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.$router.push("/viewAllCategories")
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    }
  }
};
</script>
