<template>
  <div class="container">
    <card-layout>
      <div class="row">
        <h6 class="col-2 my-auto" style="text-align: center">
          Current Status:-
        </h6>
        <div class="vr p-0"></div>
        <h6 class="col-3 m-2" :class="showStatusClass" v-if="cleanData">
          <b>{{ cleanData.status }}</b>
        </h6>
      </div>
      <hr class="p-1" />
      <div class="row">
        <h6 class="col-2 my-auto" style="text-align: center">Status:-</h6>
        <div class="vr p-0"></div>
        <div class="col">
          <select
            name="status"
            class="form-control col-4 m-2"
            v-model="selectedStatus"
          >
            <option value="NOT_APPROVED">
              Not Approved
            </option>
            <option value="REJECTED">Rejected</option>
            <option value="APPROVED">Approved</option>
          </select>
          <textarea
            class="form-control col-4 m-2"
            rows="5"
            placeholder="Remarks"
            v-model="remarks"
            v-if="selectedStatus !== 'NOT_APPROVED'"
          ></textarea>
          <base-button
            class="col-3 m-2"
            classType="info"
            @click="updateStatusHandler"
            v-if="
              selectedStatus !== 'NOT_APPROVED' ||
              (cleanData && remarks !== cleanData.remarks)
            "
            >Update <i class="fa fa-pencil"></i
          ></base-button>
        </div>
      </div>
    </card-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cleanData: null,
      selectedStatus: "NOT_APPROVED",
      remarks: null,
    };
  },
  props: ["cleanId"],
  computed: {
    showStatusClass() {
      if (this.cleanData.status === "NOT_APPROVED") {
        return "text-warning";
      } else if (this.cleanData.status === "REJECTED") {
        return "text-danger";
      } else {
        return "text-success";
      }
    },
  },
  methods: {
    fetchCleaningData() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_SINGLE_CLEANING_DATA +
            "/" +
            this.cleanId,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.cleanData = res.data.data;
            this.selectedStatus = this.cleanData.status;
            this.remarks = this.cleanData.remarks;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    updateStatusHandler() {
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.UPDATE_CLEANING_STATUS +
            "/" +
            this.cleanId,
          {
            remarks: this.remarks,
            status: this.selectedStatus,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$token,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.fetchCleaningData()
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchCleaningData();
  },
};
</script>
