<template>
  <div class="container col-6 mt-5">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Forgot password</h3>
      </template>
      <template #default>
        <form class="form-horizontal" @submit.prevent="submitHandler()">
          <div class="card-body">
            <div class="form-group row">
              <label for="first-name" class="col-sm-2 col-form-label"
                >Your Email<AsteriskSymbol
              /></label>
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="email"
                  :class="emailClass"
                  placeholder="piyush@gmail.com"
                />
                <ErrorMessage v-if="emailError">
                  <p v-for="err in emailError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <button
                type="submit"
                class="btn btn-info col-2"
                v-if="!showLoader"
              >
                Send Email
              </button>
              <loading-screen v-else></loading-screen>
              <button type="reset" class="btn btn-default col-2 ml-auto">
                Clear
              </button>
            </div>
          </div>
        </form>
      </template>
    </base-card>
  </div>
</template>
<script>
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import ErrorMessage from "../UI/ErrorMessage.vue";
import LoadingScreen from "../shared/LoadingScreen.vue";
export default {
  data() {
    return {
      email: "",
      emailError: null,
      showLoader:false,
    };
  },
  components: {
    AsteriskSymbol,
    ErrorMessage,
    LoadingScreen,
  },
  computed: {
    emailClass() {
      return this.Error ? "form-control is-invalid" : "form-control";
    },
  },
  methods:{
    submitHandler(){
        this.showLoader=true;
        this.axios.post(this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.FORGOT_PASSWORD,
        {
            email:this.email
        }).then(res=>{
            this.showLoader = false;
            if(res.status === 200){
                this.$Methods.successAlert(res.data.message);
            }
        }).catch(err=>{
            this.showLoader = false;
            this.$Methods.errorAlert(err.response.data.message);
        })
    }
  }
};
</script>
