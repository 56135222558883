<template>
  <div class="col-md-9 mx-auto mt-2">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Add New Department</h3>
      </template>
      <template #default>
        <form class="form-horizontal" @submit.prevent="submitHandler()">
          <div class="card-body">
            <div class="form-group row">
              <label for="ac_company" class="col-sm-3 col-form-label"
                >Select Building<AsteriskSymbol
              /></label>
              <div class="col-sm-9">
                <multi-select
                  v-model="building"
                  :options="allBuilding"
                  :searchable="true"
                  placeholder="Select Building"
                ></multi-select>

                <ErrorMessage v-if="buildingError">
                  <p v-for="err in buildingError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>
            <div class="form-group row">
              <label for="first-name" class="col-sm-3 col-form-label"
                >Department Name<AsteriskSymbol
              /></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  v-model="deptName"
                  class="form-control"
                  placeholder="MCA"
                />
                <ErrorMessage v-if="deptNameError">
                  <p v-for="err in deptNameError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-info">Add New Dept</button>
          </div>
        </form>
      </template>
    </base-card>
  </div>
</template>
<script>
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import ErrorMessage from "../UI/ErrorMessage.vue";
export default {
  components: {
    AsteriskSymbol,
    ErrorMessage,
  },
  data() {
    return {
      allBuilding: {},
      deptName: null,
      building: null,

      deptNameError: false,
      buildingError: false,
    };
  },
  methods: {
    renameKey(obj, oldKey, newKey, oldKey1, newKey1) {
      obj[newKey] = obj[oldKey];
      obj[newKey1] = obj[oldKey1];
      delete obj[oldKey];
      delete obj[oldKey1];
    },
    submitHandler() {
      this.axios
        .post(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.DEPARTMENT_API,
          {
            department_name: this.deptName,
            building_name: this.building,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.$router.push("/showAllDept");
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
          if (err.response.status === 422) {
            if (err.response.data.errors.department_name) {
              this.deptNameError = err.response.data.errors.department_name;
            } else {
              this.deptNameError = false;
            }
            if (err.response.data.errors.building_name) {
              this.buildingError = err.response.data.errors.building_name;
            } else {
              this.buildingError = false;
            }
          }
        });
    },

    fetchAllBuilding() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ALL_BUILDINGS,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const arr = res.data.data;
            arr.forEach((obj) =>
              this.renameKey(
                obj,
                "building_name",
                "label",
                "building_id",
                "value"
              )
            );
            const updated = arr;
            this.allBuilding = updated;
          }
        })
        .catch((err) => {
          console.log(err);
          //   this.$Methods.toastError(err.response.data.message);
          this.$Methods.toastError("Error in Fetching buildings");
        });
    },
  },
  mounted() {
    this.fetchAllBuilding();
  },
};
</script>
