<template>
  <div class="col">
    {{ building.alias }}
  </div>
  <hr/>
  <div class="col">
    Total Resources : {{ building.resource_count }}
  </div>
  <div class="col">
    Total Sub Resources : {{ building.subresource_count }}
  </div>
  <div class="col">
    Total AC : {{ building.ac_count }}
  </div>

</template>
<script>
export default {
  props: ["building"],
};
</script>
