<template>
  <div class="container">
  <div class="col-12">
    <div class="card card-primary">
      <div class="card-header">
        <h4 class="card-title">Cleaning Images</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-2" v-for="url in imgUrls" :key="url">
            <a
              :href="url"
              data-toggle="lightbox"
              data-title="sample 1 - white"
              data-gallery="gallery"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img :src="url" class="img-fluid mb-2" alt="white sample" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="container">
    <card-layout>
      <div class="row" v-if="cleaningDetails">
        <div class="col" v-if="location">
          <iframe
            :src="location"
            width="100%"
            height="100%"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <!-- <div class="vr p-0 mx-2"></div> -->
        <div class="col">
          <h5>Details</h5>
          <table class="table table-sm">
            <tr>
              <td>Caretaker Name</td>
              <td v-if="cleaningDetails">
                {{
                  cleaningDetails.staff_firstname +
                  " " +
                  cleaningDetails.staff_lastname
                }}
              </td>
            </tr>
            <tr>
              <td>Caretaker Unique Id</td>
              <td v-if="cleaningDetails">
                {{ cleaningDetails.staff_unique_id }}
              </td>
            </tr>
            <tr >
              <td>Status</td>
              <td v-if="cleaningDetails" ><b><span :class="statusRowClass">{{ cleaningDetails.status }}</span></b></td>
            </tr>
            <tr>
              <td>Cleaned At</td>
              <td v-if="cleaningDetails">{{ cleaningDetails.created_at }}</td>
            </tr>
            <tr>
              <td>AC Name</td>
              <td v-if="cleaningDetails">{{ cleaningDetails.unique_alias }}</td>
            </tr>
            <tr>
              <td>AC capacity</td>
              <td v-if="cleaningDetails">{{ cleaningDetails.ac_capacity }}</td>
            </tr>
            <tr>
              <td>AC Type</td>
              <td v-if="cleaningDetails">{{ cleaningDetails.ac_type }}</td>
            </tr>
            <tr>
              <td>Sub Resource</td>
              <td v-if="cleaningDetails">
                {{ cleaningDetails.sub_resource_name }}
              </td>
            </tr>
            <tr>
              <td>Resource</td>
              <td v-if="cleaningDetails">
                {{ cleaningDetails.resource_name }}
              </td>
            </tr>
            <tr>
              <td>Building</td>
              <td v-if="cleaningDetails">
                {{ cleaningDetails.building_name }}
              </td>
            </tr>
            <tr>
              <td>AC Company</td>
              <td v-if="cleaningDetails">{{ cleaningDetails.company_name }}</td>
            </tr>
          </table>
        </div>
      </div>
    </card-layout>
  </div>

  <cleaning-status-changer :cleanId="cleanId"> </cleaning-status-changer>
</template>
<style scoped>
img {
  border-radius: 4px;
  border: 1px solid black;
}
img:hover {
  transform: scale(1.01);
  box-shadow: 0 0 3px 2px rgba(26, 26, 26, 0.5);
}
</style>
<script>
import CleaningStatusChanger from "./CleaningStatusChanger.vue";
export default {
  data() {
    return {
      imgUrls: [],
      cleaningDetails: null,
      location: null,
    };
  },
  props: ["cleanId"],
  components: { CleaningStatusChanger },
  computed: {
    statusRowClass() {
      if (this.cleaningDetails && this.cleaningDetails.status === "APPROVED") {
        return "text-success";
      } else {
        return "text-danger";
      }
    },
  },
  methods: {
    fetchImageUrls() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_CLEANING_IMAGE +
            "/" +
            this.cleanId,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.imgUrls = res.data.data.paths;
            this.cleaningDetails = res.data.data.details;
            this.location = res.data.data.location;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchImageUrls();
  },
};
</script>
