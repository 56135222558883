<template>
  <iframe src="https://maps.google.com/maps?q=23.0073251,72.4592136&hl=en&z=17&amp;output=embed" width="700" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</template>

<script>
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  data() {
    return {
      date: null,
    };
  },
  methods: {
    hello() {
      this.$Methods.debounce(function () {
        alert("fdsjhkfgdsfdhs");
      }, 1000);
    },
  },
};
</script>
