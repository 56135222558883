<template>
  <button :class="getClassType"><slot></slot></button>
</template>
<script>
export default {
  props: ["classType"],
  computed:{
    getClassType(){
        if(this.classType === 'warning-g'){
            return 'btn btn-block bg-gradient-warning';
        }
        else if(this.classType === 'danger-g'){
            return 'btn btn-block bg-gradient-danger'
        }
        else if(this.classType === 'outline-info'){
          return 'btn btn-outline-info';
        }
        else if(this.classType === 'info'){
          return 'btn btn-info';
        }
        else{
            return 'btn btn-block bg-gradient-primary'
        }
    }
  }
};
</script>
