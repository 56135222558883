<template>
  <nav class="navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li><h4><b><router-link to="/" class="nav-link">AC Cleaning System</router-link></b></h4></li>
    </ul>
    <!-- right ravbar links -->
    <ul class="navbar-nav ml-auto">
        <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Home</router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/login" class="nav-link">Login</router-link>
      </li>
    </ul>

  </nav>
</template>
