<template>
  <div :class="cardClass">
    <div class="card-header">
      <slot name="card-header"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: ["cardClass"],
};
</script>
